/**
 * User settings.
 * @module settings
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { ErrorWrap } from '../comp/errorwrap';
import { BusinessCard } from './user-detail';
import { useTranslation } from 'react-i18next';

//seen all - OK

/**
 * Personal user setting overview - user information, user roles for user who has any, password change form
 * 
 * @param {any} userlogin - current user info
 * @returns {component}
 */
export function Settings({ userlogin }) {
	const { t } = useTranslation();
	//console.log(userlogin);
	return (
		<ErrorWrap>
			<Row>
				<Col md={4}>
					<BusinessCard user={userlogin.userinfo} />
				</Col>
				<Col md={4}>
					<Card body>
						<h5>{t('userinfo')}</h5>
						<Row>
							<Col className='text-muted text-end'>{t('sys-username')}</Col>
							<Col>{userlogin.username}</Col>
						</Row>
						<Row>
							<Col className='text-muted text-end'>{t('sys-worker_code')}</Col>
							<Col>{userlogin.userinfo.tw_kodpracovnika}</Col>
						</Row>
						<Row>
							<Col className='text-muted text-end'>{t('sys-his_mail')}</Col>
							<Col>{userlogin.userinfo.his_email}</Col>
						</Row>
						<Row>
							<Col className='text-muted text-end'>
								{userlogin.userinfo.his_superuser || userlogin.userinfo.role_compliance || userlogin.userinfo.role_csr_head ?
									"Role"
									:
									""
								}
							</Col>
							<Col>
								{userlogin.userinfo.his_superuser ? <>{t('sys-admin')} ✔️<br /></> : ""}
								{userlogin.userinfo.role_compliance ? <>{t('sys-compliance')} ✔️<br /></> : ""}
								{userlogin.userinfo.role_csr_head ? <>{t('sys-CSR_manager')} ✔️<br /></> : ""}
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
		</ErrorWrap>
	);
}
