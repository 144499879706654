/**
 * Commponent for app rendering of delivery note sales detail
 * @module delivery-notes/delivery-note-sales-detail-app
 * @author Lucie Zdeňková <ĺucie.zdenek@trustica.cz>
 */
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import ListGroup from 'react-bootstrap/ListGroup';
import { date_formatCZ } from '../lib/date-utils';
import { DisplayADR } from './delivery-notes-sales-detail-web';
import { LinkContainer } from 'react-router-bootstrap';
import { dnote_field_ok, null_or_qmark, HeaderOrItemField } from './delivery-note-sales-checks';
import { ErrorWrap } from '../comp/errorwrap';
import { useTranslation } from 'react-i18next';

//seen all - OK

export function PDItemsMobile({ items, header, ErrorBadge }) {
	//console.log(items);
	return (
		<Accordion className="d-xl-none">
			{
				items.map(function (item, idx) {
					return <SingleItemMobilePD
						key={idx}
						item={item}
						header={header}
						ErrorBadge={ErrorBadge}
					/>;
				})}
		</Accordion>
	);
}

export function SingleItemMobilePD({ item, header, ErrorBadge }) {
	const { t } = useTranslation();
	return (
		<ErrorWrap>
			<Accordion.Item eventKey={item.p_k_IDProduktu}>
				<Accordion.Header as="div">
					<div>
						<b>{t('prod-name')}:</b> {item.svp_NazevProduktu}
						<br />
						<b>{t('not-prod_id')}: </b>{item.p_k_IDProduktu}
					</div>
				</Accordion.Header>
				<Accordion.Body className='p-2'>
					<Card.Body className="beSmaller">
						<ListGroup variant="flush" className="smaller">
							<ListGroup.Item>
								<Row className="g-0">
									<Col>{t('deno-contract')}: {HeaderOrItemField(header.svd_KodZakazky, item.svp_KodZakazky)}
										{null_or_qmark(item.svp_KodZakazky) ? <ErrorBadge tag={"contractCode"} /> : ""}</Col>
									<Col className="text-end">{t('warehouse')}: {item.svp_KodKnihy}
										<ErrorBadge tag={"singleWarehouse"} />
									</Col>
								</Row>
							</ListGroup.Item>
							<ListGroup.Item>
								<Row>
									<Col xs="auto">
										{t('biz-centre')}: {HeaderOrItemField(header.svd_KodStrediska, item.svp_KodStrediska)}
										{null_or_qmark(item.svp_KodStrediska) ? <ErrorBadge tag={"CentreCode"} /> : ""}
									</Col>
									<Col className="text-end">{t('selling_amount_unit')}: {item.svp_Mnozstvi}&nbsp;{item.svp_KodMj}</Col>
								</Row>
							</ListGroup.Item>
							<ListGroup.Item>
								<Row>
									<Col xs="auto">{t('deno-category')}: {HeaderOrItemField(header.svd_KodKategorie, item.svp_KodKategorie)}
										{null_or_qmark(item.svp_KodKategorie) ? <ErrorBadge tag={"categoryCode"} /> : ""}
									</Col>
									<Col className="text-end">{t('warehouse_amount_unit')}: {item.PocetErrorBadge} {item.svs_MnozstviSkl}&nbsp;{item.p_KodMjSkl}</Col>
								</Row>
							</ListGroup.Item>
							<ListGroup.Item>
								<Row>
									<Col>{t('ord-expiry')}: {date_formatCZ(item.ps_DatExpirace)}
										{dnote_field_ok(item.ps_DatExpirace) ? "" :
											<>
												<ErrorBadge tag={"expiryMissing"} />
											</>
										}
									</Col>
									<Col className="text-end">{t('units_in_package')}: {item.PocetErrorBadge} {item.pjo_MnozstviSklMjVObalu}&nbsp;{item.p_KodMjSkl}</Col>
								</Row>
							</ListGroup.Item>
							<ListGroup.Item>
								<Row>
									<Col>{t('prod-ADR')}: <DisplayADR tridaADR={item.kcunc_TridaADR} /></Col>
									<Col className="text-end">{t('package_count')}: {item.PocetErrorBadge} {item.pocetStr}</Col>
								</Row>
							</ListGroup.Item>
							<ListGroup.Item>
								{t('prod-code')}:&nbsp;&nbsp;
								<LinkContainer to={{ pathname: "/products/" + encodeURIComponent(item.svp_KodProduktu) + "/view" }}>
									<a href="!#">{item.svp_KodProduktu}</a>
								</LinkContainer>
							</ListGroup.Item>
						</ListGroup>
					</Card.Body>
				</Accordion.Body>
			</Accordion.Item>
		</ErrorWrap>
	)
}