/**
 * Detail of user.
 * @module user-detail
 * @author Lucie Zdenkova <lucie.zdenek@trustica.cz>
 */
import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import { his_fetch, his_fetch_success, HisFetchStatus } from '../comp/FetchLoader';
import { Button } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { date_time_format } from '../lib/date-utils';
import { isFunction } from '../lib/utils';
import { Card } from 'react-bootstrap';
import { Image } from 'react-bootstrap';
import { ErrorWrap } from '../comp/errorwrap';
import { useTranslation } from 'react-i18next';
/*
import { ExcelDownloader } from '../comp/excel';
import translations from '../i18n/locales/cs/translations.json';
import translationssk from '../i18n/locales/sk/translations.json';
import translationsen from '../i18n/locales/en/translations.json';
*/

/**
 * Universal checkbox function with changeIt function used multiple times in UserProps
 * 
 * @param {string} label - checkbox label
 * @param {boolean} value - value of checkbox
 * @param {boolean} disabled - is checkbox editable
 * @param {function} onChange - function for changing value
 * @param {string} id  - controlId of checbox 
 * @returns {component}
 */
function Checkbox({ label, value, disabled, onChange, id }) {

    const changeIt = function () {
        if (isFunction(onChange)) {
            if (value === false) {
                onChange(true);
            } else {
                onChange(false);
            }
        }
    }

    return (
        <ErrorWrap>
            <Form.Group className="mb-3 d-inline-block me-5" controlId={id}>
                <Form.Check type="checkbox" onChange={changeIt} label={label} disabled={disabled} checked={value || false} />
            </Form.Group>
        </ErrorWrap>
    );
}

/**
 * User detail overview for admin - user's editable attributes, user's info from twist and current HIS sessions 
 * 
 * @param {any} userlogin 
 * @param {array} existingUsers - field of all existing users in his - to avoid users duplication 
 * @returns {component}
 */
export function UserDetail({ userlogin, existingUsers }) {
    const { t } = useTranslation();

    const { userID } = useParams();

    const [user, setUser] = useState(null);
    const [loadedStatus, setLoadedStatus] = useState(0);

    const [username, setUsername] = useState(null);
    const [usernameOrig, setUsernameOrig] = useState(null);
    const [oid, setOid] = useState(null);
    const [oidOrig, setOidOrig] = useState(null);
    const [workerCode, setWorkerCode] = useState("");
    const [workerCodeOrig, setWorkerCodeOrig] = useState("");
    const [hisMail, setHisMail] = useState(null);
    const [hisMailOrig, setHisMailOrig] = useState(null);
    const [editEnabled, setEditEnabled] = useState(false);
    const [saving, setSaving] = useState(false);
    const [errorMesssage, setErrorMessage] = useState("");
    const [lang, setLang] = useState(null);
    const [langOrig, setLangOrig] = useState(null);

    const reloadIt = () => {
        setUser(null);
        setLoadedStatus(0);
        setUsername(null);
        setUsernameOrig(null);
        setOid(null);
        setOidOrig(null);
        setWorkerCode(null);
        setWorkerCodeOrig(null);
        setHisMail(null);
        setHisMailOrig(null);
        setEditEnabled(false);
        setSaving(false);
        setErrorMessage("");
        setLang(null);
    }

    useEffect(() => {
        if (user === null) {
            const running_fetch = his_fetch(
                userlogin,
                [
                    {
                        uri: "/api/users/" + userID,
                        json: true,
                        status: setLoadedStatus,
                        ok: function (resource, result) {
                            //console.log(result);
                            setUser(result.user);
                            setOid(result.user.azure_user_oid);
                            setUsername(result.user.username);
                            setUsernameOrig(result.user.username);
                            setWorkerCode(result.user.tw_kodpracovnika);
                            setWorkerCodeOrig(result.user.tw_kodpracovnika);
                            setHisMail(result.user.his_email);
                            setHisMailOrig(result.user.his_email);
                            setLangOrig(result.user.language);
                            setLang(result.user.language);
                            //console.log(result.users);
                        },
                        error: function (resource, reason) {
                            console.log('err: ' + reason);
                            setUser("error");
                        }
                    }
                ]
            );
            return () => {
                running_fetch();
            }
        }
    }, [userlogin, userID, user]);

    if (!his_fetch_success(loadedStatus)) {
        return <HisFetchStatus status={loadedStatus} loadingType="big" errorType="fetcherError" reloadButton={reloadIt} />;
    }

    const existingUsername = existingUsers.includes(username);
    const invalidUsername = existingUsername && username !== usernameOrig;

    const nothingChanged = (username === usernameOrig) && (workerCode === workerCodeOrig)
        && (hisMail === hisMailOrig) && (lang === langOrig) && (oid === oidOrig || (oid === '' && oidOrig === null));

    const cannotBeSaved = nothingChanged || invalidUsername || !username || !hisMail || !workerCode;

    function saveIt() {
        if (!saving) {
            setSaving(true);
            setEditEnabled(false);

            var user = {}; //vyrábím slovník slovníků
            user['email'] = hisMail;
            user['tw_kodpracovnika'] = workerCode === "" ? null : workerCode;
            user['azure_user_oid'] = oid === "" ? null : oid;
            user['username'] = username;
            user['language'] = lang;

            const jsonData = JSON.stringify(user);
            // console.log(user);
            //console.log(jsonData);

            his_fetch(
                userlogin,
                [
                    {
                        uri: "/api/users/" + userID,
                        ok: function (resource, result) {
                            // console.log(result);
                            reloadIt();
                        },
                        error: function (resource, reason) {
                            //console.log('err: ' + reason);
                            setSaving(false);
                            reloadIt();
                            setErrorMessage(t('error'));
                        },
                        args: {
                            method: 'PUT',
                            body: jsonData,
                            headers: {
                                "Content-Type": "application/json",
                            },
                        }
                    }
                ]
            );
        }
    }

    const user_usable = user ? user : {};

    /*
    //table of all transaltions to doownload for superusers
    const csTable = Object.keys(translations).map((row) => [row, translations[row]]);
    const csSkTable = csTable.map((row) => [...row, translationssk[row[0]]]);
    const finalTable = csSkTable.map((row) => [...row, translationsen[row[0]]]);
    */

    return (
        <ErrorWrap>
            <Button size="sm" className="me-2 mb-2" onClick={reloadIt}><Image src="/img/reload.svg" height="19" /></Button>
            <h4 className="mb-2 d-inline">{t('not-user')} {user_usable.username}
                {!editEnabled && !saving ?
                    <Button size="sm" className="mb-1 ms-4" onClick={() => setEditEnabled(true)}>{t('edit')}</Button>
                    :
                    <>
                        {saving ? <Button size="sm" className="mb-1 ms-4" disabled >{t('saving')}...</Button> :
                            <>
                                <Button size="sm" className="mb-1 ms-4" disabled={cannotBeSaved} onClick={saveIt}>{t('save')}</Button>
                                <Button size="sm" className="mb-1 ms-4" variant="danger" disabled={saving} onClick={reloadIt}>{t('discard_changes')}</Button>
                            </>
                        }
                    </>
                }
                {errorMesssage ? <span className="text-danger ms-4">{errorMesssage}</span> : ""}
            </h4>

            <Row>
                <Col>
                    <UserProps userlogin={userlogin} editEnabled={editEnabled} oid={oid} setOid={setOid}
                        username={username} setUsername={setUsername} workerCode={workerCode} setWorkerCode={setWorkerCode}
                        hisMail={hisMail} setHisMail={setHisMail}
                        invalidUsername={invalidUsername} lang={lang} setLang={setLang} />
                </Col>
                <Col md="4">
                    <BusinessCard user={user_usable} />
                </Col>
            </Row>
        </ErrorWrap>
    )
}

/**
 * Form for editing user attributes - username, code, mail, roles
 * 
 * @param {*} userlogin - current user info
 * @param {boolean} editEnabled - editing is allowe
 * @param {string} username - username
 * @param {function} setUsername - setting username hook
 * @param {string} oid - azure OID of user
 * @param {function} setOid - setting OID hook
 * @param {string} workerCode - code of worker
 * @param {function} setWorkerCode - setting workerCode hook
 * @param {string} hisMail - email used for loggin into HIS
 * @param {function} setHisMail - setting email
 * @param {boolean} detailView - are we loogin at it in user-detail? then we show info, whether user is active
 * @param {boolean} invalidUsername - result of username validation
 * @returns {component}
 */
export function UserProps({ userlogin, editEnabled, username, setUsername, workerCode, setWorkerCode, hisMail, setHisMail, detailView, invalidUsername,
    lang, setLang, oid, setOid }) {
    const { t } = useTranslation();

    const [twistCodes, setTwistCodes] = useState(null);

    useEffect(() => {
        if (twistCodes === null) {
            const running_fetch = his_fetch(
                userlogin,
                [
                    {
                        uri: "/api/users/twist",
                        json: true,
                        //    status: setLoadedStatus,
                        ok: function (resource, result) {
                            //console.log(result);
                            setTwistCodes(result.twist_users);
                        },
                        error: function (resource, reason) {
                            console.log('err: ' + reason);
                            setTwistCodes("error");
                        }
                    }
                ]
            )
            return () => {
                running_fetch();
            }
        }
    }, [userlogin, twistCodes]);

    //console.log(twistCodes);

    function changeWorkerCode(ev) {
        setWorkerCode(ev.target.value);
    }

    const labelUsername = editEnabled ? t('sys-username') + " " + t('mandatory') : t('sys-username');
    const LabelMail = editEnabled ? t('sys-his_mail') + " " + t('mandatory') : t('sys-his_mail');
    const labelWorkerCode = editEnabled ? t('sys-worker_code') + " " + t('mandatory') : t('sys-worker_code');
    const labelLanguage = editEnabled ? t('sys-language') + " " + t('mandatory') : t('sys-language');

    //console.log(userlogin);
    //console.log(username);

    return (
        <ErrorWrap>
            <Card body>
                <Form>
                    <Row>
                        <Col>
                            <p style={{ marginBottom: "8px" }}>{labelUsername}</p> {/* this prevents browsers from autofill, cuz they officially ignore autoComplete="off" */}
                            <Form.Group className="mb-3" controlId="1" >
                                <Form.Control type="text" autoComplete='new-password' value={username || ""} onChange={(ev) => setUsername(ev.target.value)} disabled={!editEnabled} isInvalid={invalidUsername} />
                                <Form.Control.Feedback type="invalid">{t('username_exists_choose_different')}.</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col md="6">
                            <p className="mb-2">{labelWorkerCode}</p>
                            <Form.Control as="select" value={workerCode || ""} onChange={changeWorkerCode} disabled={!editEnabled}>
                                {workerCode ?
                                    <option value={workerCode}>{workerCode}</option>
                                    :
                                    <option disabled value="">{t('choose')}</option>}
                                {(twistCodes || []).map(function (code) {
                                    return <option key={code} value={code}>{code}</option>
                                })}
                            </Form.Control>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p style={{ marginBottom: "8px" }}>{LabelMail}</p> {/* this prevents browsers from autofill, cuz they officially ignore autoComplete="off" */}
                            <Form.Group className="mb-3" controlId="2"  >
                                <Form.Control autoComplete='new-password' type="text" value={hisMail || ""} onChange={(ev) => setHisMail(ev.target.value)} disabled={!editEnabled} />
                            </Form.Group>
                        </Col>
                        <Col md="6">
                            <p className="mb-2">{labelLanguage}</p>
                            <Form.Control as="select" value={lang} onChange={(ev) => setLang(ev.target.value)} disabled={!editEnabled}>
                                <option value='cs'>česky</option>
                                <option value='sk'>slovenski</option>
                                <option value='en'>english</option>
                            </Form.Control>
                        </Col>
                    </Row>
                    <Row>
                        <p style={{ marginBottom: "8px" }}>{t('sys-azure-oid')}</p> {/* this prevents browsers from autofill, cuz they officially ignore autoComplete="off" */}
                        <Form.Group className="mb-3" controlId="3" >
                            <Form.Control type="text" value={oid || ""} onChange={(ev) => setOid(ev.target.value)} disabled={!editEnabled} />
                        </Form.Group>
                    </Row>
                </Form>
            </Card>
        </ErrorWrap>
    )
}

/**
 * Just personal user information displayed as business card
 * 
 * @param {any} user - user information from userlogin
 * @returns {component}
 */
export function BusinessCard({ user }) {
    const { t } = useTranslation();
    return (
        <Card body>
            <div className="text-center mb-3">
                <h4> {user.titul} {user.jmeno} </h4>
                <hr className="m-0" />
                {user.pozice}
            </div>
            <Row>
                <Col xs="4" className="text-muted text-end">{t('biz-centre_code')}</Col>
                <Col>{user.kod_strediska}</Col>
            </Row>
            <Row>
                <Col xs="4" className="text-muted text-end">{t('email')}</Col>
                <Col>{user.email}</Col>
            </Row>
            <Row>
                <Col xs="4" className="text-muted text-end">{t('biz-phon')}</Col>
                <Col>{user.telefon}</Col>
            </Row>
            <Row>
                <Col xs="4" className="text-muted text-end">{t('biz-mobile')}</Col>
                <Col>{user.mobil}</Col>
            </Row>
        </Card>
    )
}