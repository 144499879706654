/**
 * Detail of an e-mail.
 * @module email-detail
 * @author Lucie Zdenkova <lucie.zdenek@trustica.cz>
 */

import parse from 'html-react-parser';
import PostalMime from 'postal-mime';
import React, { useEffect, useState } from 'react';
import { Badge, Card, Col, ListGroup, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { HisFetchStatus, his_fetch, his_fetch_success } from './comp/FetchLoader';
import { AttachmentDownloader } from './comp/downloader';
import { ErrorWrap } from './comp/errorwrap';
import { date_time_format } from './lib/date-utils';

export function EmailDetail({ userlogin }) {
    const { t } = useTranslation();

    const { code } = useParams();

    const [loadedStatus, setLoadedStatus] = useState(0);
    const [email, setEmail] = useState(null);
    const [content, setContent] = useState(null);

    //new style: začínám na null a v error funkci nastavuji na "error", viz. threads

    const reloadIt = () => {
        setEmail(null);
    }

    useEffect(() => {
        if (email === null) {
            const running_fetch = his_fetch(
                userlogin,
                [
                    {
                        uri: "/api/email/view/" + code,
                        json: true,
                        status: setLoadedStatus,
                        ok: async function (resource, result) {
                            const content = await PostalMime.parse(result.email.mime)
                            setEmail(result.email);
                            setContent(content);
                        },
                        error: function (resource, reason) {
                            setEmail("error");
                        }
                    }
                ]
            );
            return () => {
                running_fetch();
            }
        }
    }, [userlogin, email, code]);

    if (!his_fetch_success(loadedStatus) || content === null) {
        return <HisFetchStatus status={content == null ? 1 : loadedStatus} loadingType="big" errorType="fetcherError" reloadButton={reloadIt} />;
    }

    return (
        <ErrorWrap>
            <ListGroup variant="flush">
                <ListGroup.Item>
                    <Row>
                        <Col><span className="text-muted">{t('sys-from')}: </span> {email.from}</Col>
                        <Col><span className="text-muted">{t('date')}: </span>{date_time_format(email.sent)}</Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Row>
                        <Col>
                            <Row className='g-0'>
                                <Col xs="auto" className="me-1"><span className="text-muted">{t('sys-to_pl')}: </span></Col>
                                <Col>{email.to.map((a) => <React.Fragment key={a}>{a}<br /></React.Fragment>)}</Col>
                            </Row>
                        </Col>
                        <Col><span className="text-muted">ID: </span>{email.id}</Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Row>
                        <Col xs="6"><span className="text-muted">{t('sys-subject')}: </span>{email.subject}</Col>
                        <Col xs="3"><span className="text-muted">{t('sys-cc')}: </span>{email.cc.map((a) => <React.Fragment key={a}>{a}<br /></React.Fragment>)}</Col>
                        <Col xs="3"><span className="text-muted">{t('sys-bcc')}: </span>{email.bcc.map((a) => <React.Fragment key={a}>{a}<br /></React.Fragment>)}</Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item>

                    <Row>
                        <Col>
                            <MailContentVisualizer content={content} />
                        </Col>
                        <Col>
                            <pre>
                                {email.mime}
                            </pre>
                        </Col>
                    </Row>
                </ListGroup.Item>
            </ListGroup>
        </ErrorWrap>
    );
}

/**
 * Displays body of email based on type and subtype
 * 
 * @param {Email} content - dictionary with email mime content 
 * @returns {component}
 */
function MailContentVisualizer({ content }) {
    const htmlArray = []
    if (content.text) {
        htmlArray.push(<EmailPartCard key="text" description='text/plain' content={<pre>{content.text}</pre>} />)
    }
    if (content.html) {
        htmlArray.push(<EmailPartCard key="html" description='text/html' content={parse(content.html)} />)
    }
    for (const [i, attachment] of content.attachments.entries()) {
        htmlArray.push(<EmailPartCard key={`attachment_${i}`} description={attachment.mimeType} content={
            <AttachmentDownloader data={attachment.content} subtype={attachment.mimeType} alt="" height="30" filename={attachment.filename} />
        } />)
    }

    return htmlArray
}

/**
 * Single entity Card
 * 
 * @param {any} entity 
 * @param {any} content
 * @returns {component}
 */
function EmailPartCard({ description, content }) {
    const { t } = useTranslation();

    return <Card body className='my-2'>
        <p className='mb-4'>
            <Badge className='float-end fw-normal' bg="light" text="dark">
                {description}
            </Badge>
        </p>
        {content}
    </Card>;
}