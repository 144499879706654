/**
 * Notes - embedded in table eg.
 * @module notes/embedded-notes
 * @author Lucie Zdenkova <lucie.zdenek@trustica.cz>
 */
import React, { useState, useEffect } from 'react';
import { Col, Row, Button, Form, Card } from 'react-bootstrap';
import { Loading } from '../comp/loading';
import { date_time_format } from '../lib/date-utils';
import notes_comms from './notes-comms.json';
import { his_fetch, his_fetch_success, HisFetchStatus } from '../comp/FetchLoader';
import { ErrorWrap } from '../comp/errorwrap';
import { useTranslation } from 'react-i18next';

/**
 * 
 * @param {number} object_type - we distinguish types of notes => 1: Partner, 2: Invoice, 3: Product, 4: Batch
 * @param {number} object_code - identification - productId in case of Notes for batches
 * @param {number} object_code2 - batch string in case of Notes for batches
 * @param {array} enabled_notes - what kind of notes do we allow
 * @param {any} userlogin - for the fetch
 * @param {any} existingNotes - if there are notes from mother component, nothing will be fetched
 * @returns {component}
 */
export function EmbeddedNotes({ object_type, object_code, object_code2, enabled_notes = [5, 4, 3, 2, 1], userlogin, existingNotes }) {
    const { t } = useTranslation();

    // console.log(existingNotes);

    const [state, setState] = useState(0);
    const [notes, setNotes] = useState(existingNotes);
    const [selectedComm, setSelectedComm] = useState(0);
    const [preparedNote, setPreparedNote] = useState("");
    const [loadedStatus, setLoadedStatus] = useState(0);
    const [origExistingNotes, setOrigExistingNotes] = useState(existingNotes);

    if (origExistingNotes != existingNotes) {
	setNotes(existingNotes);
	setOrigExistingNotes(existingNotes);
    }

    const reloadIt = () => {
        setState(0);
        setNotes(null);
        setSelectedComm(0);
        setPreparedNote("");
        setLoadedStatus(0);
    }

    useEffect(() => {
        if (notes === undefined) {
            const running_fetch = his_fetch(
                userlogin,
                [
                    {
                        uri: "/api/notes/" + object_type + "/" + object_code + "/" + object_code2.replaceAll('/', '%2F'),
                        json: true,
                        status: setLoadedStatus,
                        ok: function (resource, result) {
                            setNotes(result.notes);
                            //console.log(result.notes);
                        },
                        error: function (resource, reason) {
                            console.log('err: ' + reason);
                            setNotes("error");
                        }
                    }
                ]
            )
            return () => {
                running_fetch();
            }
        }
    }, [userlogin, notes, object_type, object_code, object_code2]);

    const userinfo = userlogin.userinfo;
    const user = userlogin.username;


    const setupForm = function (comm) {
        setState(1);
        setPreparedNote("");
        setSelectedComm(comm);
    }

    const copynew = function (note) {
        setState(1);
        setSelectedComm(note.commId);
        setPreparedNote(note.note);
    }

    //submit new note
    const handleSubmit = function (event) {
        setState(2);
        setSelectedComm(0);

        const form = event.currentTarget;
        const note = form.note.value;
        const comm_type = selectedComm;
        const rj = {
            object_type: object_type,
            comm_type: comm_type,
            object_code: object_code.toString(),
            object_code2: object_code2,
            note: note
        };
        const rjs = JSON.stringify(rj);

        his_fetch(
            userlogin,
            [
                {
                    uri: "/api/notes",
                    json: true,
                    ok: function (resource, result) {
                        var newNotes = notes || [];
                        newNotes.unshift(result.note);
                        setState(0);
                        setSelectedComm(0);
                        setNotes(newNotes);
                    },
                    error: function (resource, reason) {
                        console.log('err: ' + reason);
                        setSelectedComm(0);
                        setState(0);
                    },
                    args: {
                        method: 'POST',
                        body: rjs,
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                }
            ]
        );
    }

    const cancelForm = function () {
        setState(0);
        setSelectedComm(0);
    }

    const handleShow = function (id) {
        his_fetch(
            userlogin,
            [
                {
                    uri: "/api/notes/" + id,
                    json: true,
                    ok: function (resource, result) {
                        var newNotes = (notes || []).map(function (note) {
                            if (note.id === id) {
                                note.h_username = null;
                            }
                            return note;
                        });
                        setNotes(newNotes);
                    },
                    error: function (resource, reason) {
                        console.log('error: ' + reason);
                        setState(reason);
                    },
                    args: {
                        method: 'PUT'
                    }
                }
            ]
        );
    }

    const handleHide = function (id) {
        his_fetch(
            userlogin,
            [
                {
                    uri: "/api/notes/" + id,
                    json: true,
                    ok: function (resource, result) {
                        var newNotes = (notes || []).map(function (note) {
                            if (note.id === id) {
                                note.h_username = user;
                            }
                            return note;
                        });
                        setNotes(newNotes);
                    },
                    error: function (resource, reason) {
                        console.log('error: ' + reason);
                        setState(reason);
                    },
                    args: {
                        method: 'DELETE'
                    }
                }
            ]
        );
    }

    const allow_copy = state === 0;
    const comm = selectedComm;

    
    if (!his_fetch_success(loadedStatus) && existingNotes === undefined) {
        return (
            <Card body>
                <HisFetchStatus status={loadedStatus} loadingType="big" errorType="fetcherError" reloadButton={reloadIt} />
            </Card>
        );
    }
    

    return (
        <ErrorWrap>
            <Card>
                <Card.Body>
                    <div>
                        {
                            enabled_notes.map(function (idx) {
                                const commdef = notes_comms[idx];
                                var variant = commdef.variant;
                                if (comm === idx) {
                                } else {
                                    variant = 'outline-' + variant;
                                }
                                const name = t(commdef.name);
                                const onclick = function () {
                                    setupForm(idx);
                                }
                                const emoji2 = commdef.emoji2;
                                return <Button key={idx} size="sm" className="mb-2 me-2" variant={variant} onClick={onclick}><img src={emoji2} alt="" width="20" /> {name}</Button>;
                            })
                        }
                    </div>
                    {
                        function () {
                            if (state === 1) {
                                return (
                                    <Form onSubmit={handleSubmit}>
                                        <Form.Group controlId="note" className='mb-2'>
                                            <Form.Label>{t('not-text')}:</Form.Label>
                                            {
                                                preparedNote === "" ?
                                                    <Form.Control as="textarea" name="note" rows={2} />
                                                    :
                                                    <Form.Control as="textarea" name="note" rows={2} defaultValue={preparedNote} />
                                            }
                                        </Form.Group>
                                        <Row>
                                            <Col xs={6}>
                                                <Button size="sm" variant="secondary" onClick={cancelForm}>{t('cancel')}</Button>
                                            </Col>
                                            <Col xs={6} className="text-end">
                                                <Button size="sm" variant="primary" type="submit">{t('rep-add')}</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                );
                            } else if (state === 2) {
                                return <Loading message={t('processing') + "... "} />;
                            } else {
                                return <></>;
                            }
                        }()
                    }

                    {
                        notes.map(function (note, idx) {
                            const commId = note.commId;
                            const commdef = notes_comms[commId];
                            const variant = commdef.variant;
                            const emoji = commdef.emoji;
                            var allow_hide = (userinfo.his_superuser || (note.username === user)) && (note.h_username === null);
                            var allow_show = (!allow_hide) && (userinfo.his_superuser || (note.username === user));
                            if (!(state === 0)) {
                                allow_hide = false;
                                allow_show = false;
                            }
                            var textclass = "";
                            if (note.h_username != null) {
                                textclass = "cross-out";
                            }
                            const dohandlehide = function () {
                                handleHide(note.id);
                            }
                            const dohandleshow = function () {
                                handleShow(note.id);
                            }
                            const docopynew = function () {
                                copynew(note);
                            }
                            return (
                                <div key={idx}>
                                    <hr />
                                    <div className="d-flex justify-content-between">
                                        <div className={textclass}>
                                            <em className='text-muted'><small>
                                                {date_time_format(note.created)}
                                                &nbsp;|&nbsp;
                                                {note.KodStrediska}
                                                &nbsp;|&nbsp;
                                                {note.Jmeno} ({note.username})
                                            </small></em>
                                        </div>
                                        <div>
                                            {
                                                allow_hide ?
                                                    <Button size="sm" variant="light" onClick={dohandlehide}>{t('not-archive')}</Button>
                                                    :
                                                    allow_show ?
                                                        <Button size="sm" variant="light" onClick={dohandleshow}>{t('not-renew')}</Button>
                                                        :
                                                        <></>
                                            }
                                        </div>
                                    </div>
                                    <p>
                                        {
                                            allow_copy ?
                                                <Button size="sm" variant={variant} onClick={docopynew} className="me-1"><img src={emoji} alt="" width="20" /></Button>
                                                :
                                                <Button size="sm" variant={variant} onClick={docopynew} className="me-1" disabled><img src={emoji} alt="" width="20" /></Button>
                                        }
                                        <span className={textclass}>
                                            {note.note}
                                        </span>
                                    </p>
                                </div>
                            );
                        })
                    }
                </Card.Body>
            </Card>
        </ErrorWrap>
    );
}

