/**
 * Detail of issued invoice
 * @module invoices/invoice-issued-detail
 * @author Dominik Pantucek <dominik.pantucek@trustica.cz>
 */
import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Card, Badge } from 'react-bootstrap';
import { Notes } from '../notes/notes';
import { format_amount } from '../lib/format';
import { useParams } from "react-router-dom";
import { date_formatCZ } from '../lib/date-utils';
import { Insured3, get_insurance_status } from '../insurance/insurance-utils';
import { his_fetch, his_fetch_success, HisFetchStatus } from '../comp/FetchLoader';
import { LinkContainer } from 'react-router-bootstrap';
import { Boolean } from '../comp/boolean';
import { ErrorWrap } from '../comp/errorwrap';
import { useTranslation } from 'react-i18next';

//seen all - OK

// cislo dokladu, VS, kod dokladu, vzstaveni, plnění, evidence, partner, nazev partnera, poznámka z faktury, částka v měně, měna, splatnost, saldo v měně, uzavřeno, zaúčtováno, zrušeno, středisko
// produkt, množství, cena mj, měna, cena celkem bez dph, s dph, středisko

export function InvoiceIssuedDetail({ user, userinfo, userlogin }) {
	let { id } = useParams();
	return <InvoiceIssuedDetailComp user={user} userinfo={userinfo} id={id} userlogin={userlogin} />
}

export function InvoiceIssuedDetailComp({ id, user, userinfo, userlogin }) {
	const { t } = useTranslation();

	const [invoiceFetched, setInvoiceFetched] = useState("");
	const [elementsFetched, setElementsFetched] = useState(null);
	const [loadedStatus, setLoadedStatus] = useState(0);
	const [error, setError] = useState("");

	const reloadIt = () => {
		setLoadedStatus(0);
		setElementsFetched(null);
		setInvoiceFetched({});
	}

	useEffect(() => {
		if (elementsFetched === null) {
			const running_fetch = his_fetch(
				userlogin,
				[
					{
						uri: "/api/invoices/issued/" + id,
						json: true,
						status: setLoadedStatus,
						ok: function (resource, result) {
							setInvoiceFetched(result.invoice);
							//console.log(result.invoice);
							setElementsFetched(result.elements);
							//console.log(result.elements);
							if (result.error) {
								setError(result.error);
							}
						},
						error: function (resource, reason) {
							console.log('err: ' + reason);
							setElementsFetched("error");
						}
					}
				]
			)
			return () => {
				running_fetch();
			}
		}
	}, [userlogin, elementsFetched, id]);

	//console.log(loadedStatus);


	if (!his_fetch_success(loadedStatus)) {
		return <HisFetchStatus status={loadedStatus} loadingType="big" errorType="fetcherError" reloadButton={reloadIt}
		/>; //	uri: "/api/invoices/issued/." + id, při tomto si to vleze do 2jky - protože to vrátí response, byť ne 200, ale 	uri: "/api/invoices/issued-/" + id failne správně
	}

	const invoice = invoiceFetched ? invoiceFetched : {};
	const elements = elementsFetched ? elementsFetched : [];

	return (
		<ErrorWrap>
			{error !== "" ? <h4 className='text-danger mb-4 mt-4'>{t('error')}: {error}</h4> : ""}
			<h4>{t('biz-inv_issued')} {invoice.tfvd_KodDokladu}</h4>
			<Row>
				<Col xl={6}>
					<Card body className="noPadding">
						<InvoiceInfo invoice={invoice} />

						<h5 className="mb-1 mt-3 ms-3">{t('biz-items')}</h5>
						<div className="p-0 d-none d-lg-block">
							<BrowserInvoiceElements elements={elements} />
						</div>
						<AppInvoiceElements elements={elements} />
					</Card>
				</Col>
				<Col xl={6}>
					<br className="d-xl-none" />
					<Card className="mb-3">
						<Card.Body>
							<Row>
								<Col><h5 className='mb-3'>{t('menu-ins')}</h5></Col>
								<Col className="text-end"><h5>
									<Insured3 status={get_insurance_status(invoice.cp_fic, invoice.cfvd_Pojisteno, invoice.ca_insured, invoice.credendo_country_ok)} /></h5></Col>
							</Row>
							<Limits invoice={invoice} />
							<Reasoning invoice={invoice} />
						</Card.Body>
						<Card.Footer className='bg-white text-center p-2 text-muted'>
							<Badge pill bg="secondary">i</Badge>&nbsp;&nbsp;
							<small>{t('biz-all_info_valid_due_date_of_issue')}</small>
						</Card.Footer>
					</Card>
					<Notes user={user} userinfo={userinfo} object_type={2} object_code={invoice.tfvd_KodDokladu} userlogin={userlogin} />
				</Col>
			</Row>
		</ErrorWrap>
	);
}

function InvoiceInfo({ invoice }) {
	const { t } = useTranslation();
	return (
		<ErrorWrap>
			<Table borderless size="sm" className="p-0 m-0">
				<tbody>
					<tr className="p-0">
						<td className="text-end text-muted p-0 ">{t('biz-doc_number')} &nbsp;</td>
						<td className="p-0">{invoice.tfvd_CisloDokladu}</td>
					</tr>
					<tr>
						<td className="text-end text-muted p-0">{t('biz-variable_symbol')} &nbsp;</td>
						<td className="p-0">{invoice.tfvd_VarSymbol}</td>
					</tr>
					<tr>
						<td className="text-end text-muted p-0">{t('biz-issued_date')} &nbsp;</td>
						<td className="p-0">{invoice.tfvd_DatDokladu ? date_formatCZ(invoice.tfvd_DatDokladu) : "-"}</td>
					</tr>
					<tr>
						<td className="text-end text-muted p-0">{t('biz-partner_code')} &nbsp;</td>
						<td className="p-0">
							<LinkContainer to={{ pathname: "/partners/list/" + invoice.tfvd_KodPartnera + "/detail" }}>
								<a href="!#">{invoice.tfvd_KodPartnera}</a>
							</LinkContainer>
						</td>
					</tr>
					<tr>
						<td className="text-end text-muted p-0">{t('note')} &nbsp;</td>
						<td className="p-0">{invoice.tfvd_Poznamka}</td>
					</tr>
					<tr>
						<td className="text-end text-muted p-0">{t('biz-amount_curr')} &nbsp;</td>
						<td className="p-0">{format_amount(invoice.tfvd_CastkaCelkemMen)} {invoice.tfvd_KodMeny}</td>
					</tr>
					<tr>
						<td className="text-end text-muted p-0">{t('biz-due')} &nbsp;</td>
						<td className="p-0">{invoice.tfvd_DatSplatnosti ? date_formatCZ(invoice.tfvd_DatSplatnosti) : "-"}</td>
					</tr>
					<tr>
						<td className="text-end text-muted p-0">{t('biz-centre')} &nbsp;</td>
						<td className="p-0">{invoice.tfvd_KodStrediska}</td>
					</tr>
					<tr>
						<td className="text-end text-muted p-0">{t('biz-closed')} &nbsp;</td>
						<td className="p-0"><Boolean value={invoice.tfvd_Uzavreno} /></td>
					</tr>
					<tr>
						<td className="text-end text-muted p-0">{t('biz-accounted')} &nbsp;</td>
						<td className="p-0"><Boolean value={invoice.tfvd_Zauctovano} /></td>
					</tr>
				</tbody>
			</Table>
		</ErrorWrap>

	);
}

function BrowserInvoiceElement({ el }) {
	return (
		<tr>
			<td className='ps-4'>{el.KodStrediskaP}</td>
			<td>
				<LinkContainer to={{ pathname: "/products/" + encodeURIComponent(el.KodProduktu) + "/view" }}>
					<a href="!#">{el.KodProduktu}</a>
				</LinkContainer>
			</td>
			<td className="text-end">{el.Mnozstvi}</td>
			<td>{el.KodMjCen}</td>
			<td className="text-end">{format_amount(el.CenaMjMen)}</td>
			<td className="text-end">{format_amount(el.CastkaBezDphMen)}</td>
			<td className="text-end">{format_amount(el.CastkaVceDphMen)}</td>
			<td>{el.KodMeny}</td>
		</tr>
	);
}

function BrowserInvoiceElements({ elements }) {
	const { t } = useTranslation();
	var idx = 0;
	return (
		<Table size="sm" striped className="bg-white">
			<thead className='beGray'>
				<tr>
					<th rowSpan="2" className="text-center" >{t('biz-centre')}</th>
					<th rowSpan="2" className="text-center">{t('product')}</th>
					<th rowSpan="2" className="text-end">{t('amount')}</th>
					<th rowSpan="2" >{t('measure_unit')}</th>
					<th colSpan="4" className="text-center">{t('price')}</th>
				</tr>
				<tr>
					<th className="text-center">{t('measure_unit')}</th>
					<th className="text-end">{t('total')}</th>
					<th className="text-end">{t('biz-total_VAT_incl')}</th>
					<th>{t('biz-currency')}</th>
				</tr>
			</thead>
			<tbody>
				{elements.map(function (el) {
					idx += 1;
					return <BrowserInvoiceElement el={el} key={idx} />;
				})}
			</tbody>
		</Table>
	);
}

//style={{transform: "rotate(-90deg)"}}
function AppInvoiceElement({ idx, el }) {
	const { t } = useTranslation();
	return (
		<tbody>
			<tr className="border-top">
				<td colSpan="2" className="text-end text-muted pb-0 pe-0">{t('biz-centre')} &nbsp;</td>
				<td className="pb-0 ps-0">{el.KodStrediskaP}</td>
			</tr>
			<tr>
				<td colSpan="2" className="text-end text-muted p-0">{t('product')} &nbsp;</td>
				<td className="p-0">
					<LinkContainer to={{ pathname: "/products/" + encodeURIComponent(el.KodProduktu) + "/view" }}>
						<a href="!#">{el.KodProduktu}</a>
					</LinkContainer></td>
			</tr>
			<tr>
				<td className="p-0 text-muted border-end text-center">&nbsp;C&nbsp;</td>
				<td className="text-end text-muted p-0">{t('amount')} &nbsp;</td>
				<td className="p-0">{el.Mnozstvi} {el.KodMjCen}</td>

			</tr>
			<tr>
				<td className="p-0 text-muted border-end text-center">&nbsp;E&nbsp;</td>
				<td className="text-end text-muted p-0">{t('measure_unit')} &nbsp;</td>
				<td className="p-0"> {format_amount(el.CenaMjMen)} {el.KodMeny}</td>
			</tr>
			<tr>
				<td className="p-0 text-muted border-end text-center">&nbsp;N&nbsp;</td>
				<td className="text-end text-muted p-0">{t('total')} &nbsp;</td>
				<td className="p-0">{format_amount(el.CastkaBezDphMen)} {el.KodMeny}</td>

			</tr>
			<tr className="border-bottom">
				<td className="p-0 text-muted border-end text-center">&nbsp;A&nbsp;</td>
				<td className="text-end text-muted pt-0 pe-0">{t('biz-total_VAT_incl')} &nbsp;</td>
				<td className="  pt-0 ps-0">{format_amount(el.CastkaVceDphMen)} {el.KodMeny}</td>

			</tr>
		</tbody>

	);
}

function AppInvoiceElements({ elements }) {
	return (
		<div className="d-lg-none">
			{elements.map(function (el, idx) {
				return (
					<Table borderless size="sm" className="p-0 m-0" key={idx}>
						<AppInvoiceElement idx={idx} el={el} key={idx} />
					</Table>
				);
			})}
		</div>
	);
}

function Limits({ invoice }) {
	const { t } = useTranslation();
	if (invoice.cfvd_AktualniLimit === null && invoice.cfvd_CelkemPojisteneSaldo === null) {
		return "";
	} else {
		return (
			<>
				<Row className="g-0">
					<Col>{t('ins-approved_valid_limit')}:</Col>
					<Col className="text-end">{format_amount(invoice.cfvd_AktualniLimit)}</Col>
					<Col xs="auto" className="text-start">&nbsp;CZK</Col>
				</Row>
				<Row className="g-0">
					<Col >{t('ins-gained_limit')} ({t('ins-incl_invoice_if_insured')}):</Col>
					<Col className="text-end">{format_amount(invoice.cfvd_CelkemPojisteneSaldo)}</Col>
					<Col xs="auto" className="text-start">&nbsp;CZK</Col>
				</Row>
			</>
		);
	}
}


function Reasoning({ invoice }) {
	const { t } = useTranslation();
	const insurance_status = get_insurance_status(invoice.cp_fic, invoice.cfvd_Pojisteno, invoice.ca_insured, invoice.credendo_country_ok);

	if (invoice.cfvd_Pojisteno === false) {
		if ((invoice.cfvd_E1Dni != null) && (invoice.cfvd_E1Dni < invoice.cfvd_E1MaxKDatu)) {
			return (
				<>
					<hr />
					<span style={{ fontWeight: "bold", color: "red" }}> &#x2717;</span>&nbsp;
					{t('ins-E1_condition_unfullfiled_cuz_unpaid_inv')} {invoice.cfvd_E1MaxKDatu} {t('ins-days_after_due_date_but_allowed_max_is')} {invoice.cfvd_E1Dni}.
				</>
			);
		} else {
			return (
				<>
					<hr />
					<span style={{ fontWeight: "bold", color: "red" }}> &#x2717;</span>&nbsp;
					{t('ins-invoce_off_limit')}.
				</>
			);
		}

	} else if (insurance_status === 0) {
		return t('biz-waiting_to_get_insurance_status') + ".";
	} else if (insurance_status === 3) {
		return t('ins-insured_from_autolimit');
	} else if (insurance_status === 4) {
		return t('ins-partner_wasnt_insured');
	} else {
		return "";
	}
}


