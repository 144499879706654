/**
 * Product detail displaying components app version.
 * @module products/product-detail-app
 * @author Lucie Zdenkova <lucie.zdenek@trustica.cz>
 */
import React from 'react';
import { Downloader } from '../comp/downloader';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { Boolean } from '../comp/boolean';
import { ShowADR } from '../comp/adr';
import { partnerLogoShort, wid2partner, whColor, whID2whNumber } from '../lists/warehouses-defs';
import { DensityAmountUnitWeightView, ProductContainerView, ProductADRView } from './product-view';
import { make_product_check_tag } from './product-checks';
import Accordion from 'react-bootstrap/Accordion';
import { mandatory_format } from './product-utils';
import { date_formatCZ } from '../lib/date-utils';
import { useTranslation } from 'react-i18next';
import Fraction from 'fraction.js';

//seen all - OK

export function HistoryApp({ preprocessedHistory, ErrorBadge }) {
	const { t } = useTranslation();

	return (
		<div className='d-lg-none'>
			{preprocessedHistory.map(function (wh) {
				return (
					wh.history.map(function (h, idx) {
						const package_weight_kg_wh_count = Fraction(h.pcr_MnozstviSklMjVObalu).mul(Fraction(h.pcr_HmotnostMjVKg));
						return (
							<div key={idx} >
								{idx === 0 ?
									<div className='bg-secondary mt-3 mb-1 p-2 text-white text-center'>{wh[0]}</div> : <></>}
								<div className="px-3">
									{t('prod-created')}: &nbsp; &nbsp; {h.pci_created ? date_formatCZ(h.pci_created) : <>&nbsp; &ndash;</>} <br />
									{t('prod-invalidated')}: {h.pci_invalidated ? date_formatCZ(h.pci_invalidated) : <>&nbsp; &ndash;</>} <br />
									<hr className='m-1' />
									{t('name')}: {h.pcr_NazevProduktu}
									<Table borderless size="sm" className='p-0 mb-0'>
										<tbody>
											<ProductContainerView id="hsh"
												container={h.pcr_k_HSHObal5}
												ErrorBadge={ErrorBadge} mobile />
											<DensityAmountUnitWeightView
												id={"hsh"}
												density={h.pcr_HmotnostMjVKg}
												densityUnit={"kg"}
												amount={h.pcr_MnozstviSklMjVObalu}
												unit={h.pcr_KodMjSkl}
												packageWeight={String(package_weight_kg_wh_count)}
												ErrorBadge={ErrorBadge} mobile
											/>
										</tbody>
									</Table >
									<hr className='m-1' />
									<ProductADRView id="hsh"
										TridaADR={h.pcr_TridaADR}
										k_ADRObalovaSkupina={h.pcr_k_ADRObalovaSkupina}
										k_ADRUNCislo={h.pcr_k_ADRUNCislo}
										ErrorBadge={ErrorBadge} mobile romanized
									/>
									<hr className='m-1' />
									<Row>
										<Col>
											BL: &nbsp;
											{h.pcr_safety_sheet === null ? "?" : <Boolean value={h.pcr_safety_sheet} variant="true_false" />}
										</Col>
										<Col className='text-center'>
											{t('ord-batch')}: &nbsp;
											{mandatory_format(h.pcr_gw_check_batch)}
										</Col>
										<Col className='text-end'>
											BBD: &nbsp;
											{mandatory_format(h.pcr_gw_check_bbd)}
										</Col>
									</Row>
								</div>
								<hr className='mt-2 mb-0' />
								<hr className='m-0' />
								<hr className='m-0' />
								<hr className='m-0' />
								<hr className='m-0' />
								<hr className='mb-2 mt-0' />
							</div>
						)
					}));
			})}
		</div>
	);
}

/**


										
 */
export function ProductCompareApp({ product, ErrorBadge, package_weight_kg_hsh, preprocessedWarehouses, sendingStatus,
	approved, configured, no_errors, sendTheCard, cancelSending, errorMess }) {
	const { t } = useTranslation();
	return (
		<div className='d-lg-none'>
			<div className='p-3'>
				<Row className="g-0">
					<Col xs="auto">
						<Image src="/hsh.png" style={{ width: "50px", padding: "2px" }} />
					</Col>
					<Col className='text-end pt-2'>
						{t('prod-ADR')} – <Boolean value={product.k_ADR} />
						<ShowADR isADR={product.k_ADR} height={25} />
					</Col>
				</Row>
				{product.NazevProduktu}
				<Table borderless size="sm" className='p-0'>
					<tbody>
						<ProductContainerView id="hsh"
							container={product.k_HSHObal5}
							ErrorBadge={ErrorBadge} mobile />
						<DensityAmountUnitWeightView
							id={"hsh"}
							density={product.HmotnostMj}
							densityUnit={product.MjHmotnostiMj}
							amount={product.MnozstviSklMjVObalu}
							unit={product.KodMjSkl}
							packageWeight={package_weight_kg_hsh}
							ErrorBadge={ErrorBadge} mobile
						/>

					</tbody>
				</Table>
				<hr className='m-1' />
				<ProductADRView id="hsh"
					TridaADR={product.TridaADR}
					k_ADRObalovaSkupina={product.k_ADRObalovaSkupina}
					k_ADRUNCislo={product.k_ADRUNCislo}
					ErrorBadge={ErrorBadge} mobile
				/>
			</div>
			{preprocessedWarehouses.map(function (wh, idx) {
				const disableSend = ((wh.states_1_2_3 && !wh.readback_confirmed) ||
					(wh.ADRBelongsToADRWarehouse && !wh.compliance_can_send_ADR_anywhere) ||
					!!sendingStatus[wh[0]]) ||
					(!approved) || (!configured) || (!no_errors);
				return (
					<Accordion key={idx}>
						<ProductLineWarehouseApp wh={wh} ErrorBadge={ErrorBadge}
							product={product} sendingStatus={sendingStatus} sendTheCard={sendTheCard}
							disableSend={disableSend} cancelSending={cancelSending} errorMess={errorMess} />
					</Accordion>
				);
			})
			}
		</div>
	);
}

function ProductLineWarehouseApp({ wh, ErrorBadge, product, sendingStatus, sendTheCard, disableSend, cancelSending, errorMess }) {
	const { t } = useTranslation();
	return (
		<>
			<Accordion.Item eventKey={wh[0]}>
				<Accordion.Header>
					<Row className='container-fluid g-0'>
						<Col>{partnerLogoShort[wid2partner[wh[0]]]} &nbsp;{whID2whNumber[wh[0]]}</Col>
						<Col className='text-end pt-2 pe-3'>{t('prod-card_in_warehouse')}: {wh.isProductInWarehouse ? t('yes') : t('no')}</Col>
					</Row>
				</Accordion.Header>
				<Accordion.Body >
					{wh.isProductInWarehouse ?
						<>
							{t('name')} : <span className={wh.superClass5}>{wh.nazevPrefix}</span>{wh.nazevSuffix}
							<Table borderless size="sm" className='m-0 p-0'>
								<tbody>
									<ProductContainerView id={'wh-' + wh[0]}
										wid={wh[0]}
										container={wh.piw.pcr_k_HSHObal5}
										remap
										ErrorBadge={ErrorBadge} mobile />
									<DensityAmountUnitWeightView
										id={"wh-" + wh[0]} density={wh.piw.pcr_HmotnostMjVKg}
										densityUnit="kg" amount={wh.piw.pcr_MnozstviSklMjVObalu}
										unit={wh.piw.pcr_KodMjSkl} packageWeight={wh.package_weight_kg_wh}
										ErrorBadge={ErrorBadge} mobile
									/>
								</tbody>
							</Table>
							<hr className='m-1' />
							<ProductADRView id={'wh-' + wh[0]}
								TridaADR={wh.piw.pcr_TridaADR}
								k_ADRObalovaSkupina={wh.piw.pcr_k_ADRObalovaSkupina}
								k_ADRUNCislo={wh.piw.pcr_k_ADRUNCislo}
								romanized
								ErrorBadge={ErrorBadge} mobile />

						</>
						:
						t('prod-product_card_isnt_in_warehouse')}
					<hr className='mt-1 mb-2' />
					{t('prod-xlsx_card')}:
					<Downloader img="/application-excel.svg" alt="xlsx" height="25"
						label={wid2partner[wh[0]].toLowerCase()}
						path={"/api/products/" + product.KodProduktu + "/card/" + wid2partner[wh[0]]}
						filename={"karta-" + product.k_IDProduktu + "-" + wid2partner[wh[0]].toLowerCase() + ".xlsx"} />
					<br />
					{t('prod-communication_status')}: {wh.warehouse_status_str} <br />
					{t('prod-sent(f)')}: {wh.sent_status_str} <br />
					<div className={wh.received_class}>{t('prod-accepted')}: {wh.received_status_str}</div>
					{t('prod-readback')}: <div className={wh.readback_class + " d-inline "}> {wh.readback_datetime_str}</div><br />
					{t('prod-check_state')}: {wh.stavKontroly} <br />
					<hr className='m-1' />
					<Row>
						<Col>{t('prod-send_action')}:</Col>
						<Col className='text-end'>
							{wh.states_1_2_3 && !wh.readback_confirmed ? "" :
								<>
									{sendingStatus[wh[0]] ?
										<Button style={{ backgroundColor: whColor[wid2partner[wh[0]]], border: "none" }} disabled>{wh.isProductInWarehouse ? t('updating') : t('sending')}</Button>
										:
										<>
											<Button title={wh.title} style={{ backgroundColor: whColor[wid2partner[wh[0]]], border: "none" }} onClick={() => sendTheCard(wh[0])} disabled={disableSend}>
												{wh.isProductInWarehouse ? t('update') + " " + whID2whNumber[wh[0]] : t('prod-sent_to') + " " + whID2whNumber[wh[0]]}
												{wh[0] === 22 ? "" : <ErrorBadge tag={make_product_check_tag("hsh", "isADR")} />}
											</Button>
										</>
									}
								</>
							}
							{wh.show_timeDiff}
							{wh.showCancel ? <Button onClick={() => cancelSending(wh[0])} className="mt-1" size="sm" variant="danger">{t('storno')}</Button> : ""}
							<br /><small className="text-danger">{errorMess[wh[0]]}</small>
						</Col>
					</Row>
				</Accordion.Body>
			</Accordion.Item>
		</>
	)
}